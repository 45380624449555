@import 'dna-animation';

html,
body {
	overflow-x: hidden;
}

body {
  height: 100000px;
  font-family: 'Barlow', sans-serif;
}

.border {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: inset 0 0 0 10px red;
	z-index: 10;
	pointer-events: none;
}

.container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vh;
	height: 100vw;
	overflow: hidden;
	z-index: 8;
}

svg {
	position: fixed;
	top: 0;	width: 300vw;
	height: 100vh;
	right: 0;
	transform-origin: 100% 50%;
	transform: scale(3);
}

#dna-curve-2 {
	opacity: .3;
	transform: scale(2.9);
}

path {
	fill: transparent;
}

text {
	text-transform: uppercase;
	font-size: 40px;
	letter-spacing: .2em;
	font-weight: 300;
}

.non-special-word {
	font-weight: 100;
}

.special-word,
.dna-word {
	font-weight: 700;
}

.special-word,
.dna-word {
	fill: transparent;
	stroke-width: 2;
	stroke: black;
	transition: fill 300ms ease-out, stroke-width 300ms ease-out, stroke 300ms ease-out;
	mix-blend-mode: difference;
}

.dna-word.active,
.special-word.active {
	stroke: none;
	stroke-width: 0;
	fill: black;
}

.dna-word {
	stroke: red;
	font-weight: 900;
}

.dna-word.active {
	fill: red;
}

.song-info,
.song-date,
.project-info,
.scroll-progress,
.song-artist {
	position: fixed;
	z-index: 1;
	top: 40px;
	left: 45px;
	margin: 0;
	font-family: 'Barlow', sans-serif;
	font-size: 26px;
}

.song-info {
	left: 64px;
}

.scroll-progress {
	left: 50%;
	transform: translateX(-50%);
	z-index: 1000;
}

.song-date {
	left: auto;
	right: 45px;
	top: 45px;
	font-weight: 400;
	transform-origin: 100% 0;
	transform: rotate(90deg) translateX(100%);
}

.song-artist {
	top: auto;
	bottom: 45px;
	transform-origin: 0 100%;
	transform: rotate(-90deg) translateY(100%);
}

.project-info {
	left: auto;
	top: auto;
	right: 45px;
	bottom: 45px;
	opacity: .5;
}

.project-info span {
	font-size: 16px;
	font-weight: 100;
}

.project-info h3 {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
	display: inline;
	padding-bottom: 0;
	border-bottom: 1px solid rgba(0,0,0,0.3);
}

.song-divider {
	font-weight: 100;
}

.song-info {
	color: red;
	letter-spacing: .1em;
}

.song-artist {
	font-weight: 400;
}

.scroll-info {
	position: fixed;
	z-index: 2;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: .1em;
	text-align: center;
}

.scroll-info .mouse {
	width: 20px;
	height: 34px;
	vertical-align: middle;
	margin-bottom: 14px;
	border-radius: 14px;
	border: 2px solid black;
	position: relative;
	display: inline-block;
}

.scroll-info .mouse .wheel {
	position: absolute;
	top: 4px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 4px;
	width: 4px;
	height: 8px;
	background: black;
	animation: scroll 1.3s ease-out infinite forwards;
}

@keyframes scroll {
  0% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(6px); }
  100% { transform: translateX(-50%) translateY(0); }
}

.filtered-images,
.filtered-image {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1;
	list-style: none;
	margin: 0;
	padding: 0;
}

.filtered-image {
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity 200ms ease-out;

	&.active {
		opacity: 1;
	}
}

.filtered-image span {
	opacity: .65;
	height: 100vh;
	width: 100vw;
	position: absolute;
	text-align: center;
	line-height: 100vh;
	display: block;
	top: 0;
	left: 0;

	&:first-child {
		position: static;
	}

	img {
		max-height: 100vh;
		max-width: 100vw;
	}
}
