$width: 4px;
$height: 34px;
$n: 8;
$t: 4s;
$gap: 6px;

.dna-animation {
	width: (2 + $gap*2) * $n;
	height: $height;
	perspective: 400px;
	transform-style: preserve-3d;
	position: fixed;
	top: 40px;
	left: 40px;
}

.dna-animation-element {
	width: 1px;
	height: $height;
	float: left;
	margin: 0 $gap;
	border-left: 1px rgba(0,0,0,.1) solid;
	position: relative;
	transform: rotateX(-360deg);
	animation: run 4s linear infinite;

	&:before,
	&:after {
		box-sizing: border-box;
		content: '';
		width: $width;
		height: $width;
		background: none;
		border: 1px solid red;
		border-radius: 50%;
		position: absolute;
		left: 50%;
		transform: translateX(-70%);
	}
	&:before {
		top: -2px;
		border-color: black;
	}
	&:after {
		bottom: -2px;
	}
	@for $i from 1 through $n {
		&:nth-of-type(#{$i}) {
			animation-delay: $t / $n * $i * -1;
		}
	}
}

@keyframes run {
	to {
		transform: none;
	}
}
